import axios from '../jsonapi-axios';

export const getProductsByTaxonomy = async (environment: string, modality: string, volume: string) => {
  // Decode the environment, modality and volume args.
  environment = decodeURIComponent(environment);
  modality = decodeURIComponent(modality);
  volume = decodeURIComponent(volume);

  let url = new URL(`${ process.env.REACT_APP_JSONAPI_ENDPOINT }/node/html_content`);

  let fields = {
    'fields[node--html_content]': 'field_nod_product_m',
    'fields[node--product]': [
      'title',
      'field_med_image_product'
    ].join(','),
    'fields[media--image]': 'field_media_image',
    'fields[file--file]': [
      'uri',
      'meta'
    ].join(',')
  };

  Object.keys(fields).forEach( (key) => {
    url.searchParams.append(
      key, fields[key]
    )
  });

  let include = [
    'field_nod_product_m',
    'field_nod_product_m.field_med_image_product',
    'field_nod_product_m.field_med_image_product.field_media_image',
  ];

  url.searchParams.append('include', include.join(',') );

  let filters = {
    'filter[environment][condition][path]': 'field_tax_environments_m.name',
    'filter[environment][condition][value]': `${ environment.toLowerCase() }`,

    'filter[modality][condition][path]': 'field_tax_modalities_m.name',
    'filter[modality][condition][value]': `${ modality.toLowerCase() }`,

    'filter[volume][condition][path]': 'field_tax_volume.name',
    'filter[volume][condition][value]': `${ volume.toLowerCase() }`,
  };

  Object.keys(filters).forEach( (key) => {
    url.searchParams.append(
      key, filters[key]
    )
  });

  return await axios.get(url.href);
}
