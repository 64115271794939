import { deserialize } from "deserialize-json-api";

/**
 * Returns function which will process our JSONAPI data. Expects response.data from request.
 * - maps included objects to resources
 * - returns mapped data only
 */
export function deserializer (responseData: any) {
  const jsonapi = deserialize(responseData);
  return jsonapi.data;
}
