import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from "../Image/Image.component";
import "./product-thumbnail.scss";
import { ITaxonomyFilter } from '../interfaces/taxonomy.interface';
import { IProduct } from '../interfaces/product.interface';

interface IProps extends ITaxonomyFilter {
  product: IProduct;
}

export const ProductThumbnail: React.FunctionComponent<IProps> = ({ product, environment, modality, volume }) => {
  let image = {
    src: 'https://via.placeholder.com/250x250',
    alt: 'Temporary Image',
  }

  if (product.field_med_image_product) {
    let imageRaw = Array.isArray(product.field_med_image_product)
      ? product.field_med_image_product[0].field_media_image
      : product.field_med_image_product.field_media_image;

    image = {
      src: imageRaw.links.product_teaser.href,
      alt: imageRaw.meta.alt,
    }
  }

  return (
    <div className="product-thumbnail">
      <h3 className="product-thumbnail__heading">
        <Link to={ `/${ environment }/${ modality }/${ volume }/${ encodeURIComponent(product.title) }` }>
          { product.title }
        </Link>
      </h3>

      <div className="product-thumbnail__image">
        <Link to={ `/${ environment }/${ modality }/${ volume }/${ encodeURIComponent(product.title) }` }>
          <Image {...image} />
        </Link>
      </div>
    </div>
  )
}
