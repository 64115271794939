import React from 'react';
import { IMetaData } from '../interfaces/common.interface';

interface IProps extends IMetaData {
  src: any;
}

export const Image: React.FunctionComponent<IProps> = ({ src, alt, height, title, width }) => {
  // --------------------------------------------------
  // START JSX OUTPUT

  return (
    <img
      src={ src }
      alt={ alt }
    />
  );
}
