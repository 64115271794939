import React from 'react';

import "./site-header.scss";

import { KonicaLogo } from './KonicaLogo/KonicaLogo';

const SiteHeader: React.FunctionComponent = () => {
  return(
    <header className="site-header">
      <div className="site-header__logo">
        <KonicaLogo />
      </div>
    </header>
  )
}

export default SiteHeader;
