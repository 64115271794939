import React from 'react';
import "./video.scss"
import { vimeoGetPlayerUrl } from "../../utils/vimeo";
import { IVideo } from '../interfaces/video.interface';

interface IProps {
  video: IVideo;
  responsive?: boolean;
}

const Video: React.FunctionComponent<IProps> = ({ video, responsive = false }) => {
  let aspectRatio = 0;
  let style: React.CSSProperties = {};

  if (responsive && video.width && video.height) {
    aspectRatio = video.width / video.height;
    style = { '--aspect-ratio': aspectRatio } as React.CSSProperties;
  }

  return (
    <div className="video" style={ style }>
      <iframe className=""
              title={ video.title }
              src={ vimeoGetPlayerUrl(video.src) }
              width={ video.width }
              height={ video.height }
              frameBorder="0">
      </iframe>
    </div>
  );
}

export default Video;
