/**
 * Convert vimeo web address to iframe endpoint
 * @param videoUrl
 */
export function vimeoGetPlayerUrl(videoUrl: string) {
  let url = new URL(videoUrl);

  if (url.hostname === 'vimeo.com') {
    const path = url.pathname.split('/');
    return `https://player.vimeo.com/video/${ path[1] }`;
  }

  return videoUrl;
}
