import React from "react";
import { Back, gsap } from "gsap";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";

import { kebabCase } from "s360-fundamental-toolkit";

import './product-finder.scss';

import { ReactComponent as ClinicOffice } from '../../assets/images/environments/clinic-office.svg';
import { ReactComponent as EmergencyDepartment } from '../../assets/images/environments/emergency-department.svg';
import { ReactComponent as HospitalImaging } from '../../assets/images/environments/hospital-imaging.svg';
import { ReactComponent as ImagingCenter } from '../../assets/images/environments/imaging-center.svg';
import { ReactComponent as UrgentCare } from '../../assets/images/environments/urgent-care.svg';

import { ReactComponent as Chest } from '../../assets/images/modalities/chest.svg';
import { ReactComponent as General } from '../../assets/images/modalities/general.svg';
import { ReactComponent as MobilePortable } from '../../assets/images/modalities/mobile-portable.svg';
import { ReactComponent as DynamicImaging } from '../../assets/images/modalities/dynamic-imaging.svg';
import { ReactComponent as OrthoMsk } from '../../assets/images/modalities/ortho-msk.svg';

import { getEnvironments, getModalities } from './product-finder.api';
import { IRouteParams } from "../interfaces/route-params.interface";
import { IEnvironment } from "../interfaces/taxonomy.interface";
import { IModality } from "../interfaces/taxonomy.interface";
import { deserializer } from "../../utils/jsonapi-deserializer";

type State = typeof initialState;
type Props = {} & typeof defaultProps & RouteComponentProps<IRouteParams>;

const initialState = {
  experienceSelected: false
};
const defaultProps = {};
class ProductFinder extends React.Component<Props, State> {
  state = initialState;

  // --------------------------------------------------
  // BUILT-IN METHODS

  componentDidMount() {
    const SVG = document.querySelector('svg.product-finder');

    if (SVG) {
      let timeline = gsap.timeline({
        onComplete: () => {
          this._checkForSelectedExperience();
        }
      });

      getEnvironments()
        .then(response => {
          timeline.add(this._createEnvironments(deserializer(response.data), SVG));
        })
        .catch(error => {
          if (process.env.REACT_APP_REDIRECT_ON_ERROR !== 'false') {
            this.props.history.push('/error');
          }
          else {
            console.log('Error getting environments');
            console.log(error);
          }
        });

      getModalities()
        .then(response => {
          timeline.add(this._createModalities(deserializer(response.data), SVG));
        })
        .catch(error => {
          if (process.env.REACT_APP_REDIRECT_ON_ERROR !== 'false') {
            this.props.history.push('/error');
          }
          else {
            console.log('Error getting modalities');
            console.log(error);
          }
        });

      timeline.add(this._rings(SVG));
    }
  }

  componentDidUpdate(previousProps: Readonly<Props>, previousState: State) {
    if (this.props.match.url === '/') {
      let activeSegments = document.querySelectorAll('[class$="--active"]');

      activeSegments.forEach(segment => {
        segment.classList.remove('product-finder__segment--active');
      });

      if (previousState.experienceSelected === this.state.experienceSelected) {
        this.setState({ experienceSelected: false });
      }
    }
    else {
      if (this.state.experienceSelected === false) {
        this._checkForSelectedExperience();
      }
    }
  }

  // --------------------------------------------------
  // CLICK METHODS

  /**
   * Resets the selected wheel segments.
   *
   * @access public
   */
  handleExperienceReset() {
    this.props.history.push('/');
  }

  /**
   * Makes the selected segment active.
   *
   * @access public
   */
  handleSegmentClick(event: React.MouseEvent) {
    let target = (event.target as HTMLElement);
    let segment = (target.parentNode as HTMLElement);

    if (segment) {
      this._setActiveSegment(segment);

      // The parentNode of the segment is the wheel.
      this._rotateWheel(segment.parentNode as HTMLElement);
    }
  }

  // --------------------------------------------------
  // PRIVATE METHODS

  _checkForSelectedExperience() {
    const SVG = document.querySelector('svg.product-finder');

    // When the environment and modality props are available.
    if (
      this.props.match.params.environment &&
      this.props.match.params.modality &&
      SVG
    ) {
      // Get the environment segment.
      let environmentSegment = SVG.querySelector(`[data-segment="${decodeURIComponent(this.props.match.params.environment)}"]`) as HTMLElement;
      this._setActiveSegment(environmentSegment);
      this._rotateWheel(environmentSegment.parentNode as HTMLElement, false);

      // Get the modality segment.
      let modalitySegment = SVG.querySelector(`[data-segment="${decodeURIComponent(this.props.match.params.modality)}"]`) as HTMLElement;
      this._setActiveSegment(modalitySegment);
      this._rotateWheel(modalitySegment.parentNode as HTMLElement, false);

      this.setState({ experienceSelected: true });
    }
  }

  /**
   * Navigate the user to correct page based on the active wheel segments and
   * optionally the current route parameters.
   *
   * @access private
   */
  _gotoPage() {
    let environmentWheel = document.querySelector('[data-wheel="environment"]');
    let modailtyWheel = document.querySelector('[data-wheel="modality"]');

    if (environmentWheel && modailtyWheel) {
      let activeEnvironmentSegment = environmentWheel.querySelector('[class$="--active"]');
      let activeModailtySegment = modailtyWheel.querySelector('[class$="--active"]');

      if (activeEnvironmentSegment && activeModailtySegment) {
        let environmentName = activeEnvironmentSegment.getAttribute('data-segment');
        let modalityName = activeModailtySegment.getAttribute('data-segment');

        // Both environment and modality have been selected.
        if (environmentName && modalityName) {
          if (this.props.match.params.volume) {
            if (this.props.match.params.productName) {
              // Navigate to the ProductPage component.
              this.props.history.push(`/${ encodeURIComponent(environmentName) }/${ encodeURIComponent(modalityName) }/${ this.props.match.params.volume }/${ encodeURIComponent(this.props.match.params.productName) }`);
            }
            else {
              // Navigate to the IntroductionVolumePage.
              this.props.history.push(`/${ encodeURIComponent(environmentName) }/${ encodeURIComponent(modalityName) }/${ this.props.match.params.volume }`);
            }
          }
          else {
            // Navigate to the IntroductionPage.
            this.props.history.push(`/${ encodeURIComponent(environmentName) }/${ encodeURIComponent(modalityName) }`);
          }

          this.setState({ experienceSelected: true });
        }
      }
    }
  }

  /**
   * Rotates the passed wheel using the data-rotation from the active segment.
   *
   * @access private
   */
  _rotateWheel(wheel: HTMLElement, gotoPage: boolean = true) {
    let tdc = 180;
    let activeSegment = wheel.querySelector('[class$="--active"]');

    if (activeSegment) {
      let rotationAmount = activeSegment.getAttribute('data-rotation');

      gsap.set(wheel, {
        transformOrigin: 'center'
      });

      if (rotationAmount) {
        gsap.to(wheel, {
          rotation: `${tdc - parseInt(rotationAmount)}_short`,
          ease: Back.easeInOut,
          onComplete: () => {
            if (gotoPage) {
              this._gotoPage();
            }
          }
        });
      }
    }
  }

  /**
   * Removes the active segment, if any, and sets the passed segment active.
   *
   * @access private
   */
  _setActiveSegment(segment: HTMLElement) {
    let segmentParent = segment.parentNode;

    if (segmentParent) {
      let activeSegment = segmentParent.querySelector('[class$="--active"]');

      if (activeSegment !== null) {
        activeSegment.classList.remove('product-finder__segment--active');
      }
    }

    segment.classList.add('product-finder__segment--active');
  }

  /**
   * Animates the rings.
   *
   * @access private
   */
  _rings(svg: Element) {
    const RINGS = svg.querySelector('.product-finder__rings');

    let timeline = gsap.timeline({
      defaults: {
        duration: 1,
        ease: Back.easeInOut,
      }
    });

    if (RINGS) {
      timeline.set(RINGS.querySelectorAll('circle'), {
        transformOrigin: 'center',
        scale: 0.5,
      });

      timeline.to(RINGS.querySelectorAll('circle'), {
        opacity: 1,
        stagger: 0.05,
        scale: 1,
      });
    }

    return timeline;
  }

  /**
   * Using the environmentsData update the segments on the environments-wheel.
   *
   * @access private
   */
  _createEnvironments(environmentsData: Array<IEnvironment>, svg: Element) {
    const WHEEL = svg.querySelector('#environments-wheel');

    let timeline = gsap.timeline();

    if (WHEEL) {
      WHEEL.removeAttribute('id');
      WHEEL.setAttribute('class', 'product-finder__wheel product-finder__wheel--environments');

      environmentsData.forEach((environment: IEnvironment, i: number) => {
        const SEGMENT = WHEEL.querySelector(`#segment-${i}`);

        if (SEGMENT) {
          const SEGMENT_TEXT_PATH = SEGMENT.querySelector(`#segment-text-path-${i}`);
          const SEGMENT_PATH = SEGMENT.querySelector(`#segment-path-${i}`);

          let rotation = (360 / 5) * i;

          SEGMENT.removeAttribute('id');
          SEGMENT.setAttribute('class', `product-finder__segment product-finder__segment--${kebabCase(environment.name)}`);
          SEGMENT.setAttribute('data-rotation', rotation.toString());
          SEGMENT.setAttribute('data-segment', environment.name);

          if (SEGMENT_TEXT_PATH) {
            SEGMENT_TEXT_PATH.removeAttribute('style');
            SEGMENT_TEXT_PATH.setAttribute('id', `segment-text-${environment.drupal_internal__tid}`);
            SEGMENT_TEXT_PATH.setAttribute('class', 'product-finder__segment-text-path');
          }

          if (SEGMENT_PATH) {
            SEGMENT_PATH.removeAttribute('id');
            SEGMENT_PATH.removeAttribute('style');
            SEGMENT_PATH.setAttribute('class', 'product-finder__segment-path');
          }

          SEGMENT.append(this._createSegmentText(environment));
          SEGMENT.append(this._createSegmentIcon(environment, rotation));
        }
      });

      timeline.to(WHEEL.querySelectorAll('.product-finder__segment'), {
        opacity: 1,
        stagger: 0.15,
      });
    }

    return timeline;
  }

  /**
   * Using the modalitiesData update the segments on the modalities-wheel.
   *
   * @access private
   */
  _createModalities(modalitiesData: Array<IModality>, svg: Element) {
    const WHEEL = svg.querySelector('#modalities-wheel');

    let timeline = gsap.timeline();

    if (WHEEL) {
      WHEEL.removeAttribute('id');
      WHEEL.setAttribute('class', 'product-finder__wheel product-finder__wheel--modalities');

      modalitiesData.forEach((modality: IModality, i: number) => {
        const SEGMENT = WHEEL.querySelector(`#segment-${i}`);

        if (SEGMENT) {
          const SEGMENT_TEXT_PATH = SEGMENT.querySelector(`#segment-text-path-${i}`);
          const SEGMENT_PATH = SEGMENT.querySelector(`#segment-path-${i}`);

          let rotation = (360 / 5) * i;

          SEGMENT.removeAttribute('id');
          SEGMENT.setAttribute('class', `product-finder__segment product-finder__segment--${kebabCase(modality.name)}`);
          SEGMENT.setAttribute('data-rotation', rotation.toString());
          SEGMENT.setAttribute('data-segment', modality.name);

          if (SEGMENT_TEXT_PATH) {
            SEGMENT_TEXT_PATH.removeAttribute('style');
            SEGMENT_TEXT_PATH.setAttribute('id', `segment-text-${modality.drupal_internal__tid}`);
            SEGMENT_TEXT_PATH.setAttribute('class', 'product-finder__segment-text-path');
          }

          if (SEGMENT_PATH) {
            SEGMENT_PATH.removeAttribute('id');
            SEGMENT_PATH.removeAttribute('style');
            SEGMENT_PATH.setAttribute('class', 'product-finder__segment-path');
          }

          SEGMENT.append(this._createSegmentText(modality));
          SEGMENT.append(this._createSegmentIcon(modality, rotation));
        }
      });

      timeline.to(WHEEL.querySelectorAll('.product-finder__segment'), {
        opacity: 1,
        stagger: 0.15,
      });
    }

    return timeline;
  }

  /**
   * Write the name of the segment along the textPath.
   *
   * @access private
   */
  _createSegmentText(segment: IModality | IEnvironment) {
    let text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    let textPath = document.createElementNS('http://www.w3.org/2000/svg', 'textPath');
    let tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');

    text.setAttribute('class', 'product-finder__segment-text');

    textPath.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink');
    textPath.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `#segment-text-${ segment.drupal_internal__tid }`);
    textPath.setAttribute('text-anchor', 'middle');
    textPath.setAttribute('startOffset', '50%');
    textPath.append(tspan);

    tspan.append(segment.name);

    text.append(textPath);

    return text;
  }

  /**
   * Adds the icon to the segment using the #sprite icon_name.
   *
   * @access private
   */
  _createSegmentIcon(segment: IModality | IEnvironment, rotation: number) {
    const USE = document.createElementNS('http://www.w3.org/2000/svg', 'use');
    const SVG = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

    USE.setAttributeNS('http://www.w3.org/1999/xlink', 'href', `#sprite-${ segment.field_icon_name }`);
    USE.setAttribute('style', `transform: rotate(${ rotation }deg); transform-origin: center;`);

    SVG.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    SVG.setAttribute('x', segment.field_num_icon_x_position.toString());
    SVG.setAttribute('y', segment.field_num_icon_y_position.toString());
    SVG.setAttribute('width', segment.field_num_icon_width.toString());
    SVG.setAttribute('height', segment.field_num_icon_height.toString());
    SVG.setAttribute('class', 'product-finder__segment-icon');
    SVG.setAttribute('overflow', 'visible');
    SVG.append(USE);

    return SVG;
  }

  // --------------------------------------------------
  // START JSX OUTPUT

  render() {
    return (
      <React.Fragment>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 951.26 951.03" className="product-finder" preserveAspectRatio="xMidYMid meet">
          <g id="environments-wheel" data-wheel="environment">
            <g id="segment-0" onClick={ (e) => this.handleSegmentClick(e) }>
              <path id="segment-text-path-0"
                    d="M279.55,134.73a332.63,332.63,0,0,1,390.9,0"
                    transform="translate(0.63 0.5)"
                    style={{fill: 'none'}}/>
              <path id="segment-path-0"
                    d="M195.78,90.69a475.18,475.18,0,0,1,558.44,0L475,475Z"
                    transform="translate(0.63 0.5)"
                    style={{fill: 'none'}}/>
            </g>
            <g id="segment-1" onClick={ (e) => this.handleSegmentClick(e) }>
              <path id="segment-text-path-1"
                    d="M738.21,184A332.62,332.62,0,0,1,859,555.74"
                    transform="translate(0.63 0.5)"
                    style={{fill: 'none'}}/>
              <path id="segment-path-1"
                    d="M754.22,90.69A475.19,475.19,0,0,1,926.78,621.8L475,475Z"
                    transform="translate(0.63 0.5)"
                    style={{fill: 'none'}}/>
            </g>
            <g id="segment-2" onClick={ (e) => this.handleSegmentClick(e) }>
              <path id="segment-text-path-2"
                    d="M833.13,635.4A332.64,332.64,0,0,1,516.88,865.17"
                    transform="translate(0.63 0.5)"
                    style={{fill: 'none'}}/>
              <path id="segment-path-2"
                    d="M926.79,621.79A475.18,475.18,0,0,1,475,950V475Z"
                    transform="translate(0.63 0.5)"
                    style={{fill: 'none'}}/>
            </g>
            <g id="segment-3" onClick={ (e) => this.handleSegmentClick(e) }>
              <path id="segment-text-path-3"
                    d="M433.12,865.17A332.64,332.64,0,0,1,116.87,635.4"
                    transform="translate(0.63 0.5)"
                    style={{fill: 'none'}}/>
              <path id="segment-path-3"
                    d="M475,950A475.18,475.18,0,0,1,23.21,621.79L475,475Z"
                    transform="translate(0.63 0.5)"
                    style={{fill: 'none'}}/>
            </g>
            <g id="segment-4" onClick={ (e) => this.handleSegmentClick(e) }>
              <path id="segment-text-path-4"
                    d="M91,555.74A332.62,332.62,0,0,1,211.79,184"
                    transform="translate(0.63 0.5)"
                    style={{fill: 'none'}}/>
              <path id="segment-path-4"
                    d="M23.22,621.8A475.19,475.19,0,0,1,195.78,90.69L475,475Z"
                    transform="translate(0.63 0.5)"
                    style={{fill: 'none'}}/>
            </g>

            <g className="product-finder__spikes">
              <path d="M475,801.75l5.23-21.2,4.64-18.82c-3.47.12-6.94.21-10.43.21-3.11,0-6.2-.07-9.29-.17l4.63,18.79Z"
                    transform="translate(0.63 0.5)"/>
              <path d="M785.75,576l-18.54-11.53-16.47-10.23q-1.43,5-3,10c-1,3-2,5.88-3,8.79l19.3,1.4Z"
                    transform="translate(0.63 0.5)"/>
              <path d="M667.06,210.66l-16.69,14.08-14.82,12.49c2.89,1.94,5.75,3.92,8.57,6,2.52,1.83,5,3.7,7.42,5.59l7.3-17.92Z"
                    transform="translate(0.63 0.5)"/>
              <path d="M283,210.65l8.23,20.22,7.31,18c2.73-2.14,5.49-4.25,8.32-6.3,2.51-1.83,5.05-3.59,7.61-5.33l-14.8-12.48Z"
                    transform="translate(0.63 0.5)"/>
              <path d="M164.25,576,186,574.39,205.35,573c-1.19-3.26-2.34-6.54-3.42-9.86-1-3-1.85-5.91-2.71-8.88l-16.44,10.21Z"
                    transform="translate(0.63 0.5)"/>
            </g>
          </g>

          <g className="product-finder__rings">
            <circle className="product-finder__outer-ring"
                    cx="475.63"
                    cy="475.5"
                    r="290"/>
            <circle className="product-finder__inner-ring product-finder__inner-ring--thick"
                    cx="475.63"
                    cy="475.5"
                    r="267.5"/>
            <circle className="product-finder__inner-ring product-finder__inner-ring--thin"
                    cx="475.63"
                    cy="475.5"
                    r="251"/>
          </g>

          <g id="modalities-wheel" data-wheel="modality">
            <g id="segment-0" onClick={ (e) => this.handleSegmentClick(e) }>
              <path id="segment-text-path-0"
                    d="M377.27,304.86a166.33,166.33,0,0,1,195.46,0"
                    transform="translate(0.63 0.5)"
                    style={{fill: 'none'}}/>
              <path id="segment-path-0"
                    d="M335.39,282.84a237.61,237.61,0,0,1,279.22,0L475,475Z"
                    transform="translate(0.63 0.5)"
                    style={{fill: 'none'}}/>
            </g>
            <g id="segment-1" onClick={ (e) => this.handleSegmentClick(e) }>
              <path id="segment-text-path-1"
                    d="M606.61,329.48A166.35,166.35,0,0,1,667,515.37"
                    transform="translate(0.63 0.5)"
                    style={{fill: 'none'}}/>
              <path id="segment-path-1"
                    d="M614.61,282.84A237.61,237.61,0,0,1,700.9,548.4L475,475Z"
                    transform="translate(0.63 0.5)"
                    style={{fill: 'none'}}/>
            </g>
            <g id="segment-2" onClick={ (e) => this.handleSegmentClick(e) }>
              <path id="segment-text-path-2"
                    d="M654.07,555.2A166.34,166.34,0,0,1,495.94,670.09"
                    transform="translate(0.63 0.5)"
                    style={{fill: 'none'}}/>
              <path id="segment-path-2"
                    d="M700.9,548.4A237.62,237.62,0,0,1,475,712.52V475Z"
                    transform="translate(0.63 0.5)"
                    style={{fill: 'none'}}/>
            </g>
            <g id="segment-3" onClick={ (e) => this.handleSegmentClick(e) }>
              <path id="segment-text-path-3"
                    d="M454.06,670.09A166.34,166.34,0,0,1,295.93,555.2"
                    transform="translate(0.63 0.5)"
                    style={{fill: 'none'}}/>
              <path id="segment-path-3"
                    d="M475,712.52A237.62,237.62,0,0,1,249.1,548.4L475,475Z"
                    transform="translate(0.63 0.5)"
                    style={{fill: 'none'}}/>
            </g>
            <g id="segment-4" onClick={ (e) => this.handleSegmentClick(e) }>
              <path id="segment-text-path-4"
                    d="M283,515.37a166.35,166.35,0,0,1,60.4-185.89"
                    transform="translate(0.63 0.5)"
                    style={{fill: 'none'}}/>
              <path id="segment-path-4"
                    d="M249.1,548.4a237.61,237.61,0,0,1,86.29-265.56L475,475Z"
                    transform="translate(0.63 0.5)"
                    style={{fill: 'none'}}/>
            </g>
          </g>

          {
            this.state.experienceSelected &&
            <rect className="product-finder__mask"
              width="951.26"
              height="951.03" />
          }
        </svg>

        {
          this.state.experienceSelected &&
          <button className="product-finder__reset-button"
            onClick={ () => this.handleExperienceReset() }>Select another experience</button>
        }

        <div className="visually-hidden">
          {/* Define all the symbols for the environments */}
          <svg xmlns="http://www.w3.org/2000/svg">
            <ClinicOffice/>
            <EmergencyDepartment/>
            <HospitalImaging/>
            <ImagingCenter/>
            <UrgentCare/>
          </svg>

          {/* Define all the symbols for the modalities */}
          <svg xmlns="http://www.w3.org/2000/svg">
            <Chest/>
            <General/>
            <MobilePortable/>
            <DynamicImaging/>
            <OrthoMsk/>
          </svg>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(ProductFinder);
