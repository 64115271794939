import React from 'react';
import { Link } from 'react-router-dom';

import './konica-logo.scss';

export const KonicaLogo: React.FunctionComponent = () => {
  // --------------------------------------------------
  // START JSX OUTPUT

  return (
    <Link to="/">
      <svg role="image" className="konica-logo konica-logo--vertical" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.28 64.97">
        <g>
          <path d="M65.13,103.5c-1.11,0-2.85-.82-2.85-3.48s1.73-3.51,2.85-3.51S68,97.36,68,100s-1.74,3.48-2.86,3.48m0-8.4A4.92,4.92,0,1,0,69.86,100a4.82,4.82,0,0,0-4.73-4.92" transform="translate(-51.41 -39.97)"/>
          <path d="M138,103.5c-1.11,0-2.86-.82-2.86-3.48s1.74-3.51,2.86-3.51,2.86.85,2.86,3.51-1.74,3.48-2.86,3.48m0-8.4a4.92,4.92,0,1,0,4.73,4.92A4.82,4.82,0,0,0,138,95.1" transform="translate(-51.41 -39.97)"/>
          <rect y="55.36" width="1.78" height="9.38"/>
          <rect x="30.51" y="55.36" width="1.78" height="9.38"/>
          <path d="M91.43,96.62h.74l.09-1.25a8.81,8.81,0,0,0-1-.06c-3.62,0-6,1.92-6,4.71s2.34,4.71,6,4.71a8.81,8.81,0,0,0,1-.06l-.09-1.25h-.74c-2.48,0-4.2-1.2-4.2-3.39s1.7-3.41,4.2-3.41" transform="translate(-51.41 -39.97)"/>
          <path d="M78.13,100.93s0,0,0,0a16.66,16.66,0,0,0-5.53-5.61H71.34v9.38H73S73,97.48,73,97.46s0,0,0,0a15.58,15.58,0,0,1,5.3,7.27h1.49V95.33h-1.7s.07,5.59.07,5.6" transform="translate(-51.41 -39.97)"/>
          <path d="M130.11,100.93s0,0,0,0a16.68,16.68,0,0,0-5.54-5.61h-1.23v9.38H125s-.07-7.23-.07-7.25,0,0,0,0a15.58,15.58,0,0,1,5.3,7.27h1.49V95.33H130s.07,5.59.07,5.6" transform="translate(-51.41 -39.97)"/>
          <rect x="67.96" y="55.36" width="1.78" height="9.38"/>
          <path d="M92.79,104.71h1.69S97,98.76,97.1,98.56c.11.2,3.12,6.15,3.12,6.15h2l-5.1-9.64Z" transform="translate(-51.41 -39.97)"/>
          <path d="M158.59,95.07l-4.34,9.64H156s2.52-6,2.62-6.15c.11.2,3.12,6.15,3.12,6.15h2Z" transform="translate(-51.41 -39.97)"/>
          <path d="M56.23,99.14c1-1,3.67-3.81,3.67-3.81H57.78l-3.91,4.11a11.32,11.32,0,0,1,4.41,5.27h1.87a11.69,11.69,0,0,0-3.92-5.57" transform="translate(-51.41 -39.97)"/>
          <polygon points="94.53 55.36 92.75 55.36 92.75 64.74 98.18 64.74 98.18 63.4 94.53 63.4 94.53 55.36"/>
          <polygon points="103.92 55.36 97.37 55.36 97.37 56.7 99.75 56.7 99.75 64.74 101.53 64.74 101.53 56.7 103.92 56.7 103.92 55.36"/>
          <path d="M112.44,99.72c-.17.33-.41,1.08-.57,1.52,0,0,0,0,0,0-.16-.43-.4-1.18-.57-1.5a23,23,0,0,0-3-4.71c-.46.83-2.24,8.11-2.62,9.68h1.79s1.35-6.22,1.35-6.24,0,0,0,0a27.77,27.77,0,0,1,2.42,5.42h1.14a28.15,28.15,0,0,1,2.42-5.38s0,0,0,0l1.35,6.2H118c-.38-1.57-2.16-8.85-2.62-9.68a23.83,23.83,0,0,0-3,4.69" transform="translate(-51.41 -39.97)"/>
        </g>
        <g>
          <path className="konica-logo__globe-inner" d="M132.91,69.59a.17.17,0,0,1-.19.09l-25.95-1.53L80.83,69.68a.18.18,0,0,1-.2-.09q.14.43.3.87c0-.14.06-.22.25-.22h51.19c.18,0,.27.08.25.22.1-.29.2-.58.29-.87" transform="translate(-51.41 -39.97)"/>
          <path className="konica-logo__globe-inner" d="M133.63,63.17c-1,.07-18.46-.81-26.86-.8s-25.9.87-26.85.8c-.12,0-.21-.06-.23-.14v.8c0-.08.11-.13.23-.14,1-.06,18.46.81,26.85.8s25.9-.86,26.86-.8a.23.23,0,0,1,.22.14c0-.13,0-.27,0-.4s0-.26,0-.4a.23.23,0,0,1-.22.14" transform="translate(-51.41 -39.97)"/>
          <path className="konica-logo__globe-inner" d="M133.38,66.39c-.79,0-18-1.25-26.61-1.22S81,66.36,80.17,66.39a.25.25,0,0,1-.29-.17c0,.29.09.59.14.88,0-.12.11-.18.26-.18s20.83.57,26.49.57,26.29-.57,26.5-.57.25.06.26.18c.05-.29.1-.59.14-.88a.26.26,0,0,1-.29.17" transform="translate(-51.41 -39.97)"/>
          <path className="konica-logo__globe-inner" d="M80.63,57.27a.19.19,0,0,1,.2-.09l25.94,1.54,26-1.54a.17.17,0,0,1,.19.09c-.09-.29-.19-.58-.29-.87,0,.15-.07.23-.25.23H81.18c-.19,0-.28-.09-.25-.23q-.17.43-.3.87" transform="translate(-51.41 -39.97)"/>
          <path className="konica-logo__globe-inner" d="M80.17,60.47c.79,0,18,1.26,26.6,1.22s25.82-1.19,26.61-1.22a.28.28,0,0,1,.29.18c0-.3-.09-.59-.14-.88,0,.12-.12.18-.26.17s-20.84-.57-26.5-.57-26.29.57-26.49.57-.26,0-.26-.17c-.06.29-.1.58-.14.88a.27.27,0,0,1,.29-.18" transform="translate(-51.41 -39.97)"/>
          <path className="konica-logo__globe-inner" d="M80.75,57.12a.36.36,0,0,1,.38-.25l25.64,1.44,25.65-1.44a.35.35,0,0,1,.37.23c-.16-.51-.35-1-.55-1.52a.31.31,0,0,1-.32.44l-12.14.15h-26L81.62,56c-.26,0-.41-.17-.32-.43-.2.5-.39,1-.55,1.53" transform="translate(-51.41 -39.97)"/>
          <path className="konica-logo__globe-inner" d="M80.14,63.9c3.21.08,18.75.72,26.63.69s23.42-.61,26.64-.69c.22,0,.33.1.34.27,0-.27,0-.54,0-.81s0-.54,0-.81c0,.17-.12.28-.34.27-3.22-.08-18.76-.72-26.64-.69s-23.42.61-26.63.69c-.22,0-.33-.09-.35-.26,0,.27,0,.53,0,.8s0,.53,0,.8c0-.17.13-.27.35-.26" transform="translate(-51.41 -39.97)"/>
          <path className="konica-logo__globe-inner" d="M80,60.65a.47.47,0,0,1,.49-.36c.25,0,18.27,1,26.33,1.11s26.09-1.1,26.33-1.11a.47.47,0,0,1,.49.37c-.07-.57-.17-1.13-.29-1.69a.37.37,0,0,1-.39.37c-3.35-.07-20.76-.45-26.14-.45s-22.78.38-26.13.45a.36.36,0,0,1-.39-.38c-.12.56-.22,1.12-.3,1.69" transform="translate(-51.41 -39.97)"/>
          <path className="konica-logo__globe-inner" d="M133.59,66.05a.44.44,0,0,1-.49.37c-.24,0-18.27-1-26.33-1.1s-26.09,1.1-26.33,1.1a.46.46,0,0,1-.49-.35c.08.57.18,1.13.3,1.69a.36.36,0,0,1,.39-.38c3.35.06,20.76.44,26.13.44s22.79-.38,26.14-.44a.37.37,0,0,1,.39.37c.12-.56.22-1.13.29-1.7" transform="translate(-51.41 -39.97)"/>
          <path className="konica-logo__globe-inner" d="M132.79,69.62a.35.35,0,0,1-.37.23l-25.64-1.43L81.13,69.85a.36.36,0,0,1-.38-.25c.16.52.35,1,.55,1.53-.09-.26.06-.43.32-.43l12.15-.15h26l12.14.15a.3.3,0,0,1,.32.44c.21-.5.39-1,.55-1.52" transform="translate(-51.41 -39.97)"/>
          <path className="konica-logo__globe-outer" d="M106.77,40c11.76,0,21.79,6.54,25.46,15.58.11.28,0,.47-.31.47l-12.14.15h-26L81.62,56c-.28,0-.42-.19-.31-.47C85,46.51,95,40,106.77,40" transform="translate(-51.41 -39.97)"/>
          <path className="konica-logo__globe-outer" d="M106.77,58.89c5.38,0,22.79.39,26.14.45a.36.36,0,0,0,.38-.43c0-.3-.38-1.5-.47-1.73a.38.38,0,0,0-.4-.31L106.77,58.3,81.13,56.87a.39.39,0,0,0-.41.31c-.08.23-.41,1.43-.46,1.73a.36.36,0,0,0,.38.43c3.35-.06,20.76-.45,26.13-.45" transform="translate(-51.41 -39.97)"/>
          <path className="konica-logo__globe-outer" d="M106.77,62.13c7.88,0,23.42.61,26.64.69a.3.3,0,0,0,.35-.32c0-.37-.12-1.54-.16-1.79a.45.45,0,0,0-.5-.41c-.24,0-18.27,1-26.33,1.1s-26.09-1.1-26.33-1.1a.45.45,0,0,0-.5.41c0,.25-.15,1.42-.15,1.79,0,.2.1.33.35.32,3.21-.08,18.75-.72,26.63-.69" transform="translate(-51.41 -39.97)"/>
          <path className="konica-logo__globe-outer" d="M106.77,86.75c11.76,0,21.79-6.54,25.46-15.58.11-.28,0-.47-.31-.47l-12.14-.15h-26l-12.15.15c-.28,0-.42.19-.31.47,3.67,9,13.7,15.58,25.46,15.58" transform="translate(-51.41 -39.97)"/>
          <path className="konica-logo__globe-outer" d="M106.77,67.82c5.38,0,22.79-.38,26.14-.44a.36.36,0,0,1,.38.43c0,.29-.38,1.5-.47,1.73a.38.38,0,0,1-.4.31l-25.65-1.43L81.13,69.85a.39.39,0,0,1-.41-.31c-.08-.23-.41-1.44-.46-1.73a.36.36,0,0,1,.38-.43c3.35.06,20.76.44,26.13.44" transform="translate(-51.41 -39.97)"/>
          <path className="konica-logo__globe-outer" d="M106.77,64.59c7.88,0,23.42-.61,26.64-.69a.3.3,0,0,1,.35.32c0,.37-.12,1.54-.16,1.79a.45.45,0,0,1-.5.41c-.24,0-18.27-1-26.33-1.1s-26.09,1.1-26.33,1.1a.45.45,0,0,1-.5-.41c0-.25-.15-1.42-.15-1.79,0-.2.1-.33.35-.32,3.21.08,18.75.72,26.63.69" transform="translate(-51.41 -39.97)"/>
        </g>
      </svg>

      <svg role="image" className="konica-logo konica-logo--horizontal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 181.01 24.39">
        <g>
          <path d="M70.88,77.16c-1.47,0-3.77-1.08-3.77-4.59s2.29-4.63,3.77-4.63,3.77,1.12,3.77,4.63-2.31,4.59-3.77,4.59m0-11A6.4,6.4,0,1,0,77,72.57a6.27,6.27,0,0,0-6.15-6.39" transform="translate(-17.56 -60.38)" />
          <path d="M165.41,77.16c-1.47,0-3.77-1.08-3.77-4.59s2.29-4.63,3.77-4.63,3.77,1.12,3.77,4.63-2.31,4.59-3.77,4.59m0-11a6.4,6.4,0,1,0,6.14,6.39,6.27,6.27,0,0,0-6.14-6.39" transform="translate(-17.56 -60.38)" />
          <rect x="35.48" y="6.1" width="2.22" height="12.2" />
          <rect x="75.13" y="6.1" width="2.22" height="12.2" />
          <rect x="123.82" y="6.1" width="2.22" height="12.2" />
          <path d="M105.05,68.11h1l.11-1.58a11.9,11.9,0,0,0-1.34-.08C100.07,66.45,97,69,97,72.57s3,6.13,7.76,6.13a11.9,11.9,0,0,0,1.34-.08L106,77h-1c-3.35,0-5.56-1.74-5.56-4.48s2.22-4.45,5.56-4.45" transform="translate(-17.56 -60.38)" />
          <path d="M87.67,73.76s0,0,0,0a21.84,21.84,0,0,0-7.2-7.3H78.94v12.2H81s-.09-9.4-.09-9.42,0,0,0,0a20.51,20.51,0,0,1,7,9.45h1.71V66.47H87.58s.09,7.27.09,7.29" transform="translate(-17.56 -60.38)" />
          <path d="M155.27,73.76s0,0,0,0a21.82,21.82,0,0,0-7.19-7.3h-1.52v12.2h2.09s-.08-9.4-.08-9.42,0,0,0,0a20.38,20.38,0,0,1,7,9.45h1.72V66.47h-2.1s.09,7.27.09,7.29" transform="translate(-17.56 -60.38)" />
          <path d="M106.82,78.67h2.1s3.29-7.79,3.41-8c.15.26,4,8,4,8h2.51l-6.53-12.45Z" transform="translate(-17.56 -60.38)" />
          <path d="M192,66.22l-5.55,12.45h2.11s3.28-7.79,3.4-8c.15.26,4.06,8,4.06,8h2.51Z" transform="translate(-17.56 -60.38)" />
          <path d="M59.21,71.43c1.29-1.33,4.77-5,4.77-5H61.31l-5.07,5.34A14.79,14.79,0,0,1,62,78.67H64.3a15.22,15.22,0,0,0-5.09-7.24" transform="translate(-17.56 -60.38)" />
          <polygon points="158.13 6.1 155.91 6.1 155.91 18.3 162.97 18.3 162.97 16.61 158.13 16.61 158.13 6.1" />
          <polygon points="170.33 6.1 161.92 6.1 161.92 7.79 165.01 7.79 165.01 18.3 167.23 18.3 167.23 7.79 170.33 7.79 170.33 6.1" />
          <path d="M132.37,72.46c-.22.43-.53,1.24-.75,1.81,0,0,0,0,0,0a17.42,17.42,0,0,0-.73-1.79,33.28,33.28,0,0,0-3.74-6.31c-.59,1.08-2.92,10.46-3.4,12.5h2.22s1.75-8,1.76-8,0,0,0,0a35.9,35.9,0,0,1,3.14,7h1.47a37.34,37.34,0,0,1,3.13-7s0,0,0,0,1.78,8,1.78,8h2.22c-.48-2-2.81-11.42-3.4-12.5a33.17,33.17,0,0,0-3.73,6.29" transform="translate(-17.56 -60.38)" />
        </g>
        <g>
          <path className="konica-logo__globe-inner" d="M18.07,69.32a.2.2,0,0,1,.2-.13l13.37.75L45,69.19a.18.18,0,0,1,.19.12,7.64,7.64,0,0,0-.29-.8.16.16,0,0,1-.16.23l-6.34.08H24.86l-6.33-.08c-.14,0-.22-.09-.17-.22-.11.26-.2.53-.29.8" transform="translate(-17.56 -60.38)" />
          <path className="konica-logo__globe-inner" d="M17.75,72.85c1.68.05,9.78.38,13.89.36s12.21-.31,13.89-.36c.12,0,.18.06.18.15,0-.15,0-.29,0-.43s0-.28,0-.42c0,.09-.06.15-.18.14-1.68,0-9.78-.38-13.89-.36s-12.21.32-13.89.36c-.11,0-.17-.05-.18-.13,0,.14,0,.27,0,.41s0,.28,0,.42c0-.09.07-.14.18-.14" transform="translate(-17.56 -60.38)" />
          <path className="konica-logo__globe-inner" d="M17.65,71.16a.26.26,0,0,1,.26-.19c.13,0,9.53.54,13.73.58S45.25,71,45.37,71a.25.25,0,0,1,.26.2,8.72,8.72,0,0,0-.16-.89.18.18,0,0,1-.2.19c-1.74,0-10.83-.23-13.63-.23s-11.88.2-13.63.23a.18.18,0,0,1-.2-.19c-.06.29-.12.58-.16.88" transform="translate(-17.56 -60.38)" />
          <path className="konica-logo__globe-inner" d="M45.63,74a.24.24,0,0,1-.26.19c-.12,0-9.53-.54-13.73-.58s-13.6.58-13.73.58a.25.25,0,0,1-.26-.19c0,.3.1.6.16.89a.19.19,0,0,1,.2-.2c1.75,0,10.83.23,13.63.23s11.89-.2,13.63-.23a.19.19,0,0,1,.2.19c.07-.29.12-.59.16-.88" transform="translate(-17.56 -60.38)" />
          <path className="konica-logo__globe-inner" d="M45.21,75.84A.2.2,0,0,1,45,76l-13.38-.75L18.27,76a.2.2,0,0,1-.2-.13,7.47,7.47,0,0,0,.29.79c-.05-.13,0-.22.17-.22l6.33-.08H38.42l6.34.08a.16.16,0,0,1,.16.23,8.39,8.39,0,0,0,.29-.79" transform="translate(-17.56 -60.38)" />
          <path className="konica-logo__globe-outer" d="M31.64,60.38c6.13,0,11.36,3.4,13.28,8.12.06.15,0,.24-.16.25l-6.34.07H24.86l-6.34-.07c-.14,0-.22-.1-.16-.25,1.92-4.72,7.15-8.12,13.28-8.12" transform="translate(-17.56 -60.38)" />
          <path className="konica-logo__globe-outer"d="M31.64,70.24c2.81,0,11.89.2,13.63.23a.18.18,0,0,0,.2-.22c0-.15-.2-.78-.24-.9a.2.2,0,0,0-.21-.16l-13.38.75-13.37-.75a.2.2,0,0,0-.21.16c0,.12-.22.75-.25.9a.19.19,0,0,0,.2.22c1.75,0,10.83-.23,13.63-.23" transform="translate(-17.56 -60.38)" />
          <path className="konica-logo__globe-outer" d="M31.64,71.93c4.11,0,12.21.32,13.89.36.13,0,.18-.06.18-.17s-.06-.8-.08-.93a.24.24,0,0,0-.26-.22c-.12,0-9.53.54-13.73.58S18,71,17.91,71a.24.24,0,0,0-.26.22c0,.13-.08.74-.08.93s.05.17.18.17c1.68,0,9.78-.38,13.89-.36" transform="translate(-17.56 -60.38)" />
          <path className="konica-logo__globe-outer" d="M31.64,84.77c6.13,0,11.36-3.41,13.28-8.13a.16.16,0,0,0-.16-.24l-6.34-.08H24.86l-6.34.08a.16.16,0,0,0-.16.24c1.92,4.72,7.15,8.13,13.28,8.13" transform="translate(-17.56 -60.38)" />
          <path className="konica-logo__globe-outer" d="M31.64,74.9c2.81,0,11.89-.2,13.63-.23a.19.19,0,0,1,.2.22c0,.16-.2.78-.24.91A.2.2,0,0,1,45,76l-13.38-.75L18.27,76a.2.2,0,0,1-.21-.16c0-.13-.22-.75-.25-.91a.19.19,0,0,1,.2-.22c1.75,0,10.83.23,13.63.23" transform="translate(-17.56 -60.38)" />
          <path className="konica-logo__globe-outer" d="M31.64,73.21c4.11,0,12.21-.31,13.89-.36.13,0,.18.07.18.17s-.06.8-.08.93a.24.24,0,0,1-.26.22c-.12,0-9.53-.54-13.73-.58s-13.61.58-13.73.58a.24.24,0,0,1-.26-.22c0-.13-.08-.73-.08-.93s.05-.17.18-.17c1.68.05,9.78.38,13.89.36" transform="translate(-17.56 -60.38)" />
        </g>
      </svg>

    </Link>
  )
}
