import React from 'react';
import './video-gallery.scss';
import Video from "../Video/Video.component";
import { IVideo } from "../interfaces/video.interface";
import { getAbsoluteAssetPath } from '../../utils/get-absolute-asset-path';
import Grid from '../../shared/Grid/Grid.component';
import Modal from '../Modal/Modal.component';
import { ReactComponent as PlayIcon } from "../../assets/images/ui/noun_play_1921106.svg";

interface IProps {
  mainVideo: IVideo,
  additionalVideos?: Array<IVideo>
}

interface IState {
  modalVideo: IVideo | null
}

class VideoGallery extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      modalVideo: null
    }
  }

  openModal(video: IVideo) {
    this.setState({ modalVideo: video });
  }

  closeModal() {
    this.setState({modalVideo: null});
  }

  render() {
    let { mainVideo, additionalVideos } = this.props;

    return (
      <React.Fragment>
        <div className="video-gallery">
          <div className="video-gallery__main-video">
            <Video video={ mainVideo } responsive={ true }></Video>
          </div>

          {
            additionalVideos && additionalVideos.length > 0 &&
            <div className="video-gallery__additional-videos">
              <Grid ul columns={4}>
                {
                  additionalVideos.map((video, i) => (
                    <div className="video-thumbnail" key={i}>
                      <PlayIcon />
                      <img onClick={ () => {this.openModal(video)} }
                          src={ getAbsoluteAssetPath(video.thumbnail.src) } alt="" />
                    </div>
                  ))
                }
              </Grid>
            </div>
          }
        </div>

        {
          this.state.modalVideo &&
          <Modal close={ this.closeModal.bind(this) }>
            <Video video={ this.state.modalVideo } responsive={ true }></Video>
          </Modal>
        }
      </React.Fragment>
    );
  }
}

export default VideoGallery;
