import React from 'react';
import './modal.scss';
import { ReactComponent as CloseIcon } from "../../assets/images/ui/noun_Close_3401998.svg";


interface IProps {
  close: () => void
}

class Modal extends React.Component<IProps> {
  // --------------------------------------------------
  // BUILT-IN METHODS

  componentDidMount() {
    document.addEventListener('keydown', this._handleKeyDown);
  }

  // --------------------------------------------------
  // HANDLE USER ACTIONS

  _handleKeyDown = (event) => {
    let ESCAPE_KEY = 27;

    switch(event.keyCode) {
      case ESCAPE_KEY:
        this.props.close();
        document.removeEventListener('keydown', this._handleKeyDown);

        break;
      default:
        break;
    }
  }

  // --------------------------------------------------
  // START JSX OUTPUT

  render() {
    let { close } = this.props;

    return (
      <div className="modal" onClick={ close }>
        <button className="modal__close-button" onClick={ close }>
          <CloseIcon />
        </button>

        { this.props.children }
      </div>
    )
  }
}

export default Modal;
