import React from 'react';
import { kebabCase } from "s360-fundamental-toolkit";

import "./layout.scss";

interface IProps {
  heading?: string;
}

class Layout extends React.Component<IProps> {
  render() {
    let classes = [
      'layout',
      (this.props.heading) ? `layout--${ kebabCase(this.props.heading) }` : '',
    ];

    return (
      <section className={ classes.join(' ') }>
        <div className="layout__region layout__region--content">
          {
            this.props.heading &&
            <h3 className="layout__heading">{ this.props.heading }</h3>
          }

          { this.props.children }
        </div>
      </section>
    )
  }
}

export default Layout;
