import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Thumbs, A11y } from 'swiper';
import Grid from '../Grid/Grid.component';
import { IProductImage } from '../interfaces/product.interface';
import { Image } from '../Image/Image.component';

import './image-gallery.scss';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/a11y/a11y.scss';

interface IProps {
  images: Array<any> | any
}
interface IState {
  gallerySwiper: any;
}

SwiperCore.use([Navigation, Pagination, Thumbs, A11y]);

export class ImageGallery extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      gallerySwiper: null
    }
  }

  gallerySwiperRef = ref => {
    if (ref) this.setState({ gallerySwiper: ref });
  };

  handleClick(slide) {
    this.state.gallerySwiper.slideTo(slide);
  }

  render() {
    let { images } = this.props;

    images = Array.isArray(images)
      ? images
      : [images];

    return(
      <div className="image-gallery">
        <div className="image-gallery__slider">
          <Swiper
            navigation
            onSwiper={ (swiper) => this.gallerySwiperRef(swiper) }
          >
            {
              images.map((image: IProductImage, i) => (
                <SwiperSlide key={ i }>
                  <Image
                    { ...image.field_media_image.meta }
                    src={image.field_media_image.links.product_gallery_image.href } />
                </SwiperSlide>
              ))
            }
          </Swiper>
        </div>

        <div className="image-gallery__thumbnails">
          <Grid ul columns={3}>
            {
              images.map((image: IProductImage, i) => (
                <button key={ i } onClick={ () => this.handleClick(i) }>
                  <Image
                    { ...image.field_media_image.meta }
                    src={ image.field_media_image.links.product_gallery_thumbnail.href } />
                </button>
              ))
            }
          </Grid>
        </div>
      </div>
    )
  }
}
