import React from 'react';
import { Link } from 'react-router-dom';

export const ErrorPage: React.FunctionComponent = () => {
  // --------------------------------------------------
  // START JSX OUTPUT

  return (
    <React.Fragment>
      An error occurred while loading data, please <Link to="/">click here</Link> to select another experience.
    </React.Fragment>
  )
}
