import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {Helmet} from "react-helmet";

import "./app.scss";

import { ErrorPage } from './ErrorPage';
import ProductFinder from './shared/ProductFinder/ProductFinder.component';
import { ProductPageWithRouter } from './ProductPage';
import { IntroductionPageWithRouter } from './IntroductionPage';
import SiteHeader from './shared/SiteHeader/SiteHeader';

class App extends React.Component {
  // --------------------------------------------------
  // START JSX OUTPUT

  render() {
    return (
      <React.Fragment>
        <Helmet titleTemplate="DR Sales Presentation | %s" defaultTitle="DR Sales Presentation"></Helmet>
        <Router>
          <Switch>
            <Route path="/:environment/:modality/:volume/:productName">
              <main className="site-main site-main--product-page">
                <div className="site-main__header">
                  <SiteHeader />
                </div>
                <div className="site-main__aside">
                  <ProductFinder />
                </div>
                <div className="site-main__content">
                  <ProductPageWithRouter />
                </div>
              </main>
            </Route>

            <Route path="/:environment/:modality/:volume?">
              <main className="site-main site-main--introduction-page">
                <div className="site-main__header">
                  <SiteHeader />
                </div>
                <div className="site-main__aside">
                  <ProductFinder />
                </div>
                <div className="site-main__content">
                  <IntroductionPageWithRouter />
                </div>
              </main>
            </Route>

            <Route path="/error" exact>
              <main className="site-main site-main--error">
                <div className="site-main__header">
                  <SiteHeader />
                </div>
                <div className="site-main__content">
                  <ErrorPage />
                </div>
              </main>
            </Route>

            <Route path="/" exact>
              <main className="site-main site-main--home">
                <div className="site-main__header">
                  <SiteHeader />
                </div>
                <div className="site-main__content">
                  <ProductFinder />
                  <div className="message">Choose your environment and modality</div>
                </div>
              </main>
            </Route>
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
