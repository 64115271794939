import axios from '../utils/jsonapi-axios';

// Gets the introduction text associated with the environment/modality, excluding volume.
export const getIntroText = async (environment: string, modality: string, volume: string | undefined) => {
  // Decode the environment and modality args.
  environment = decodeURIComponent(environment);
  modality = decodeURIComponent(modality);

  if (volume !== undefined) {
    volume = decodeURIComponent(volume);
  }

  let url = new URL(`${ process.env.REACT_APP_JSONAPI_ENDPOINT }/node/html_content`);

  let fields = {
    'fields[node--html_content]': [
      'body',
      'field_tax_environments_m',
      'field_tax_modalities_m',
      'field_tax_volume'
    ].join(','),
    'fields[taxonomy_term--environments]': 'name',
    'fields[taxonomy_term--modalities]': 'name',
    'fields[taxonomy_term--volumes]': 'name'
  };

  Object.keys(fields).forEach(key => {
    url.searchParams.append(
      key, fields[key]
    )
  });

  let include = [
    'field_tax_environments_m',
    'field_tax_modalities_m',
    'field_tax_volume'
  ];

  url.searchParams.append('include', include.join(',') );

  let filters = {
    'filter[environment][condition][path]': 'field_tax_environments_m.name',
    'filter[environment][condition][value]': `${ environment.toLowerCase() }`,

    'filter[modality][condition][path]': 'field_tax_modalities_m.name',
    'filter[modality][condition][value]': `${ modality.toLowerCase() }`,
  };

  if (volume === undefined) {
    filters['filter[volume][condition][path]'] = 'field_tax_volume';
    filters['filter[volume][condition][operator]'] = 'IS NULL';
  }
  else {
    filters['filter[volume][condition][path]'] = 'field_tax_volume.name';
    filters['filter[volume][condition][value]'] = `${ volume.toLowerCase() }`;
  }

  Object.keys(filters).forEach((key) => {
    url.searchParams.append(
      key, filters[key]
    )
  });

  return await axios.get(url.href);
}

// Gets all the volumes associated with the environment/modality.
export const getIntroTextVolumes = async (environment: string, modality: string) => {
  // Decode the environment and modality args.
  environment = decodeURIComponent(environment);
  modality = decodeURIComponent(modality);

  let url = new URL(`${ process.env.REACT_APP_JSONAPI_ENDPOINT }/node/html_content`);

  let fields = {
    'fields[node--html_content]': 'field_tax_volume',
    'fields[taxonomy_term--volume]': 'name'
  };

  Object.keys(fields).forEach( key => {
    url.searchParams.append(
      key, fields[key]
    )
  });

  let include = [
    'field_tax_volume'
  ];

  include.forEach( item => {
    url.searchParams.append(
      'include', item
    )
  });

  let filters = {
    'filter[environment][condition][path]': 'field_tax_environments_m.name',
    'filter[environment][condition][value]': `${ environment.toLowerCase() }`,

    'filter[modality][condition][path]': 'field_tax_modalities_m.name',
    'filter[modality][condition][value]': `${ modality.toLowerCase() }`,

    'filter[volume][condition][path]': 'field_tax_volume',
    'filter[volume][condition][operator]': 'IS NOT NULL',
  };

  Object.keys(filters).forEach( (key) => {
    url.searchParams.append(
      key, filters[key]
    )
  });

  return await axios.get(url.href);
}
