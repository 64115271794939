import axios from '../utils/jsonapi-axios';

export const getProductDetails = async(productName: string) => {
  // Decode the productName arg.
  productName = decodeURIComponent(productName);

  let url = new URL(`${ process.env.REACT_APP_JSONAPI_ENDPOINT }/node/product`);

  let fields = {
    'fields[node--product]': [
      'title',
      'body',
      'field_med_image_product',
      'field_med_video_m',
      'field_dbl_key_feature_m',
      'field_med_document_brochure_m'
    ].join(','),
    'fields[media--remote_video]': [
      'name',
      'thumbnail',
      'field_media_oembed_video',
      'field_media_num_height',
      'field_media_num_width'
    ].join(','),
    'fields[media--document]': [
      'name',
      'thumbnail',
      'field_media_document'
    ].join(','),
    'fields[media--image]': 'field_media_image',
    'fields[file--file]': 'uri',
  };

  Object.keys(fields).forEach( key => {
    url.searchParams.append(
      key, fields[key]
    )
  });

  let include = [
    'field_med_image_product',
    'field_med_image_product.field_media_image',
    'field_med_video_m.thumbnail',
    'field_med_document_brochure_m.thumbnail',
    'field_med_document_brochure_m.field_media_document'
  ];

  url.searchParams.append('include', include.join(','));

  let filters = {
    'filter[product][condition][path]': 'title',
    'filter[product][condition][value]': `${ productName.toLowerCase() }`,
  };

  Object.keys(filters).forEach( (key) => {
    url.searchParams.append(
      key, filters[key]
    )
  });

  return await axios.get(url.href);
}
