// node_module imports.
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// Interface imports.
import { IRouteParams } from '../shared/interfaces/route-params.interface';
import { IProduct, IProductBrochure, IProductImage, IProductKeyFeature, IProductVideo } from '../shared/interfaces/product.interface';
import { IVideo } from "../shared/interfaces/video.interface";
import { ITaxonomyFilter } from '../shared/interfaces/taxonomy.interface';
import { IBrochure } from '../shared/interfaces/brochure.interface';

import { getProductDetails } from './product-page.api';

import { deserializer } from "../utils/jsonapi-deserializer";
import { getProductsByTaxonomy } from '../utils/api/get-products-by-taxonomy.api';

import Brochure from '../shared/Brochure/Brochure.component';
import Grid from '../shared/Grid/Grid.component';
import Layout from '../shared/Layout/Layout.component';
import { ProductThumbnail } from '../shared/ProductThumbnail';
import VideoGallery from "../shared/VideoGallery/VideoGallery.component";
import { ImageGallery } from "../shared/ImageGallery";

import "./product-page.scss";

import { ReactComponent as ArrowIcon } from "../assets/images/ui/arrow-icon.svg";

interface IState extends ITaxonomyFilter {
  id: string;
  body: {
    __html: string
  };
  images: Array<IProductImage> | IProductImage;
  mainVideo: IVideo | null;
  additionalVideos: Array<IVideo>;
  brochures: Array<IBrochure>;
  productName: string;
  productKeyFeatures: Array<IProductKeyFeature>;
  productThumbnails: Array<IProduct>;
  ogImage: string;
  description: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {};

class ProductPage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      id: '',
      body: {
        __html: ''
      },
      mainVideo: null,
      additionalVideos: [],
      brochures: [],
      environment: '',
      modality: '',
      volume: '',
      productName: '',
      productKeyFeatures: [],
      productThumbnails: [],
      images: [],
      ogImage: '',
      description: '',
    };
  }

  componentDidMount() {
    this._loadData();
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    let { environment, modality, volume, productName } = this.props.match.params;

    if (
      (environment && (prevProps.match.params.environment !== environment)) ||
      (modality && (prevProps.match.params.modality !== modality)) ||
      (volume && (prevProps.match.params.volume !== volume))
      ) {
        let stateObj = {};

      stateObj['environment'] = environment;
      stateObj['modality'] = modality;
      stateObj['volume'] = volume;

      this.setState(stateObj);
    }

    if (productName && (prevProps.match.params.productName !== productName)) {
      this._loadData();
    }
  }

  _loadData() {
    const { environment, modality, volume, productName } = this.props.match.params;
    let stateObj = {};

    if (productName) {
      stateObj['environment'] = environment;
      stateObj['modality'] = modality;
      stateObj['volume'] = volume;

      getProductDetails(productName)
        .then(response => {
          const jsonapi = deserializer(response.data);

          jsonapi.forEach((product: IProduct) => {
            try {
              stateObj['ogImage'] = product.field_med_image_product[0].field_media_image.links.og_image.href;
            }
            catch (e: any) { }

            stateObj['description'] = product.body.summary;
            stateObj['id'] = product.id;
            stateObj['productName'] = product.title;

            if (product.body) {
              stateObj['body'] = { __html: product.body.value };
            }

            if (product.field_dbl_key_feature_m) {
              stateObj['productKeyFeatures'] = product.field_dbl_key_feature_m
            }

            if (product.field_med_video_m) {
              const allVideos = product.field_med_video_m.map((video: IProductVideo) => {
                return {
                  title: video.name,
                  src: video.field_media_oembed_video,
                  height: video.field_media_num_height,
                  width: video.field_media_num_width,
                  thumbnail: {
                    src: video.thumbnail.uri.url,
                    metadata: video.thumbnail.meta
                  }
                } as IVideo
              });

              stateObj['mainVideo'] = allVideos[0];

              allVideos.shift();
              stateObj['additionalVideos'] = allVideos;
            }

            if (product.field_med_document_brochure_m) {
              const brochures = product.field_med_document_brochure_m.map((brochure: IProductBrochure) => {
                return {
                  title: brochure.name,
                  src: brochure.field_media_document.uri.url,
                  thumbnail: {
                    src: brochure.thumbnail.uri.url,
                    metadata: brochure.thumbnail.meta
                  }
                } as IBrochure
              });

              stateObj['brochures'] = brochures;
            }

            if (product.field_med_image_product) {
              stateObj['images'] = product.field_med_image_product;
            }

            this.setState(stateObj);
          });
        })
        .catch(error => {
          if (process.env.REACT_APP_REDIRECT_ON_ERROR !== 'false') {
            this.props.history.push('/');
          }
          else {
            console.log('Error', error)
          }
        });
    }

    if (environment && modality && volume) {
      getProductsByTaxonomy(environment, modality, volume)
        .then(response => {
          const jsonapi = deserializer(response.data);

          if (jsonapi.length > 0) {
            this.setState({ productThumbnails: jsonapi[0].field_nod_product_m });
          }
        })
        .catch(err => {
          console.log('Error loading products')
        });
    }
  }

  // --------------------------------------------------
  // CLICK METHODS

  handleRequestInfo() {
    let trackingCodes = [
      `utc_environment=${ this.state.environment }`,
      `utc_modality=${ this.state.modality }`,
      `utc_volume=${ this.state.volume }`,
      `utc_product=${ this.state.productName }`,
      `utc_solution=Digital Radiography`
    ];

    let queryParams = `?${ trackingCodes.join('&') }`;

    window.open(`https://healthcare.konicaminolta.us/inquirycontact-us/?${ queryParams }`, '_blank');
  }

  // --------------------------------------------------
  // JSX OUTPUT

  render() {
    return (
      <React.Fragment>
        <Helmet
          title={ this.state.productName }
          meta={[
            { name:     'description',    content: this.state.description },
            { property: 'og:title',       content: this.state.productName },
            { property: 'og:url',         content: window.location.href },
            { property: 'og:image',       content: this.state.ogImage },
            { property: 'og:description', content: this.state.description }
          ]}
        />

        <article className="node node--product">
          <header className="node__header">
            <h1 className="node__heading">
              <strong>{ this.state.productName }</strong>
            </h1>

            <div className="node__subtitle">
              <Link className="arrow-icon arrow-icon--right" to={ `/${ this.state.environment }/${ this.state.modality }/${ this.state.volume }` }>
                <ArrowIcon /> Back
              </Link>
            </div>
          </header>

          <div className="node__body" dangerouslySetInnerHTML={ this.state.body } />

          <div className="node__layout-sections">
            {
              this.state.productKeyFeatures && this.state.productKeyFeatures.length > 0 &&
              <Layout heading="Key Features">
                <dl className="node__key-features page__key-features">
                  {
                    this.state.productKeyFeatures.map((keyFeature: IProductKeyFeature, i: number) => {
                      return (
                        <React.Fragment key={ i }>
                          <dt>{ keyFeature.first }</dt>
                          <dd>{ keyFeature.second }</dd>
                        </React.Fragment>
                      )
                    })
                  }
                </dl>
              </Layout>
            }

            {
              this.state.images &&
              <Layout heading="Images">
                <ImageGallery images={ this.state.images }></ImageGallery>
              </Layout>
            }

            {
              this.state.mainVideo &&
              <Layout heading="Videos">
                <VideoGallery
                  mainVideo={ this.state.mainVideo }
                  additionalVideos={ this.state.additionalVideos } />
              </Layout>
            }

            {
              this.state.brochures && this.state.brochures.length > 0 &&
              <Layout heading="Brochures">
                <Grid ul columns={5}>
                  {
                    this.state.brochures.map((brochure, i: number) => {
                      return (<Brochure brochure={ brochure } key={ i } />)
                    })
                  }
                </Grid>
              </Layout>
            }

            <Layout>
              <div className="node__cta">
                <button
                  onClick={ () => this.handleRequestInfo() }
                  className="node__request-info-button">Request Information</button>
              </div>
            </Layout>

            {
              this.state.id && this.state.productThumbnails &&
              <Layout heading="Explore other products">
                <Grid ul columns={4}>
                  {
                    this.state.productThumbnails
                      .filter(product => product.id !== this.state.id)
                      .map((productThumbnail, i) => (
                        <ProductThumbnail key={i}
                                          product={productThumbnail}
                                          environment={this.state.environment}
                                          modality={this.state.modality}
                                          volume={this.state.volume}/>
                      ))
                  }
                </Grid>
              </Layout>
            }
          </div>
        </article>
      </React.Fragment>
    )
  }
}

export const ProductPageWithRouter = withRouter(ProductPage);
