import React from 'react';
import { IBrochure } from '../interfaces/brochure.interface';
import { IMetaData } from '../interfaces/common.interface';
import { getAbsoluteAssetPath } from '../../utils/get-absolute-asset-path';
import { Image } from '../Image/Image.component'

interface IProps {
  brochure: IBrochure;
}

const Brochure: React.FunctionComponent<IProps> = ({ brochure }) => {
  const image = {
    src: getAbsoluteAssetPath(brochure.thumbnail.src),
    alt: brochure.thumbnail.metadata.alt
  };

  return (
    <figure className="media media--file">
      <a href={ getAbsoluteAssetPath(brochure.src) } download>
        <Image { ...image } />

        <figcaption className="media__figcaption">
          { brochure.title }
        </figcaption>
      </a>
    </figure>
  );
}

export default Brochure;

