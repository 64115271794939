import axios from "../../utils/jsonapi-axios";

const TAXONOMY_FIELDS = [
  'name',
  'drupal_internal__tid',
  'field_num_icon_x_position',
  'field_num_icon_y_position',
  'field_num_icon_width',
  'field_num_icon_height',
  'field_icon_name'
];

export const getEnvironments = async () => {
  let url = new URL(`${ process.env.REACT_APP_JSONAPI_ENDPOINT }/taxonomy_term/environments`);

  url.searchParams.append('fields[taxonomy_term--environments]', TAXONOMY_FIELDS.join(','));

  return await axios.get(url.href);
}

export const getModalities = async () => {
  let url = new URL(`${ process.env.REACT_APP_JSONAPI_ENDPOINT }/taxonomy_term/modalities`);

  url.searchParams.append('fields[taxonomy_term--modalities]', TAXONOMY_FIELDS.join(','));

  return await axios.get(url.href);
}
