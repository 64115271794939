import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, withRouter } from "react-router-dom";
import {Helmet} from "react-helmet";
import { kebabCase } from "s360-fundamental-toolkit";

import { ReactComponent as ArrowIcon } from "../assets/images/ui/arrow-icon.svg";
import { deserializer } from "../utils/jsonapi-deserializer";

import { getIntroText, getIntroTextVolumes } from './introduction-page.api';

import { IIntroductionText } from './introduction-page.interface';
import { IRouteParams } from '../shared/interfaces/route-params.interface';
import { IVolume } from "../shared/interfaces/taxonomy.interface";
import { IProduct } from '../shared/interfaces/product.interface';

import { getProductsByTaxonomy } from '../utils/api/get-products-by-taxonomy.api';

import Grid from '../shared/Grid/Grid.component';
import { ProductThumbnail } from '../shared/ProductThumbnail';

interface IState {
  heading: {
    __html: string
  };
  body: {
    __html: string
  };
  showHighVolume: boolean;
  showMediumVolume: boolean;
  showLowVolume: boolean;
  environment: string;
  modality: string;
  volume: string;
  products: Array<IProduct>;
}

interface IProps extends RouteComponentProps<IRouteParams> {};

class IntroductionPage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      heading: {
        __html: ''
      },
      body: {
        __html: ''
      },
      showHighVolume: false,
      showMediumVolume: false,
      showLowVolume: false,
      environment: '',
      modality: '',
      volume: '',
      products: []
    };
  }

  // --------------------------------------------------
  // BUILT-IN METHODS

  componentDidMount() {
    this._loadData()
  }

  componentDidUpdate(previousProps: Readonly<IProps>) {
    if (
      previousProps.match.params.environment !== this.props.match.params.environment ||
      previousProps.match.params.modality !== this.props.match.params.modality ||
      previousProps.match.params.volume !== this.props.match.params.volume
    ) {
      this._loadData();
    }
  }

  // --------------------------------------------------
  // PRIVATE METHODS

  _loadData() {
    const { environment, modality, volume } = this.props.match.params;

    let stateObj = {};

    if (environment && modality) {
      stateObj['environment'] = environment;
      stateObj['modality'] = modality;
      stateObj['volume'] = (volume === undefined) ? '' : volume;
      stateObj['heading'] = {
        __html: [
          (volume === undefined ? '' : volume + ' Volume'),
          `<strong>${ decodeURIComponent(environment) }</strong>`,
          '&mdash;',
          `${ decodeURIComponent(modality) }`
        ].join(' ')
      };

      getIntroText(environment, modality, volume)
        .then(response => {
          const jsonapi = deserializer(response.data);

          jsonapi.forEach((introText: IIntroductionText) => {
            stateObj['body'] = { __html: introText.body.value };
          });

          this.setState(stateObj);
        })
        .catch(error => {
          if (process.env.REACT_APP_REDIRECT_ON_ERROR !== 'false') {
            this.props.history.push('/');
          }
          else {
            console.log('Error getting Intro Text', error);
          }
        });

      if (!volume) {
        // Gets all the associated volume texts for the environment/modality.
        getIntroTextVolumes(environment, modality)
          .then(response => {
            const jsonapi = deserializer(response.data);

            jsonapi.forEach((introText: IIntroductionText) => {
              if (introText.field_tax_volume) {
                introText.field_tax_volume.forEach((volume: IVolume) => {
                  this.setState({ ...this.state, [`show${ volume.name }Volume`]: true });
                });
              }
            })
          })
          .catch(error => {
            if (process.env.REACT_APP_REDIRECT_ON_ERROR !== 'false') {
              this.props.history.push('/error');
            }
            else {
              console.log('Error getting intro text.', error);
            }
          });

          this.setState({ products: [] });
      }
      else {
        getProductsByTaxonomy(environment, modality, volume)
          .then(response => {
            const jsonapi = deserializer(response.data);

            if (jsonapi.length > 0) {
              this.setState({ products: jsonapi[0].field_nod_product_m });
            }
          })
          .catch(error => {
            if (process.env.REACT_APP_REDIRECT_ON_ERROR !== 'false') {
              this.props.history.push('/error');
            }
            else {
              console.log('Error loading products', error);
            }
          });

        // Hides all the volumes.
        stateObj['showHighVolume'] = false;
        stateObj['showMediumVolume'] = false;
        stateObj['showLowVolume'] = false;

        this.setState(stateObj);
      }
    }
  }

  // --------------------------------------------------
  // START JSX OUTPUT

  render() {
    let classes = [
      'node',
      'node--html-content',
      `node--environment-${ kebabCase(decodeURIComponent(this.state.environment)) }`,
      `node--modality-${ kebabCase(decodeURIComponent(this.state.modality)) }`
    ];

    return (
      <React.Fragment>
        <Helmet
          title={ ((this.state.volume !== '') ? `${this.state.volume} Volume ` : '') + `${ decodeURIComponent(this.state.environment) } - ${ decodeURIComponent(this.state.modality) }` }
        />
        <article className={ classes.join(' ') }>
          <header className="node__header">
            <h1 className="node__heading" dangerouslySetInnerHTML={ this.state.heading } />
            <div className="node__subtitle">
              <Link className="arrow-icon arrow-icon--right" to={
                (this.state.volume !== '')
                  ? `/${ this.state.environment }/${ this.state.modality }`
                  : `/`
              }>
                <ArrowIcon /> Back
              </Link>
            </div>
          </header>

          <div className="node__body" dangerouslySetInnerHTML={ this.state.body } />

          {/* Only show the volumes when any of them are available */}
          {
            (this.state.showHighVolume || this.state.showMediumVolume || this.state.showLowVolume) &&
            <div className="node__volumes">
              <ul className="menu node__volume-list">
                {
                  this.state.showHighVolume &&
                  <li className="menu__item node__volume-list-item">
                    <Link className="arrow-icon arrow-icon--left" to={ `/${ this.state.environment }/${ this.state.modality }/High` }>
                      <ArrowIcon /> High Volume
                    </Link>
                  </li>
                }
                {
                  this.state.showMediumVolume &&
                  <li className="menu__item node__volume-list-item">
                    <Link className="arrow-icon arrow-icon--left" to={ `/${ this.state.environment }/${ this.state.modality }/Medium` }>
                      <ArrowIcon /> Medium Volume
                    </Link>
                  </li>
                }
                {
                  this.state.showLowVolume &&
                  <li className="menu__item node__volume-list-item">
                    <Link className="arrow-icon arrow-icon--left" to={ `/${ this.state.environment }/${ this.state.modality }/Low` }>
                      <ArrowIcon/> Low Volume
                    </Link>
                  </li>
                }
              </ul>
            </div>
          }

          {
            this.state.products && this.state.products.length > 0 &&
            <div className="node__products">
              <Grid ul columns={3}>
                {
                  this.state.products.map((product: IProduct, i: number) => {
                    return (<ProductThumbnail key={ i }
                                              product={ product }
                                              environment={ this.state.environment }
                                              modality={ this.state.modality }
                                              volume={ this.state.volume } />)
                  })
                }
              </Grid>
            </div>
          }
        </article>
      </React.Fragment>
    );
  }
}

export const IntroductionPageWithRouter = withRouter(IntroductionPage);
