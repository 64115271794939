import axios from "axios";

/**
 * Creates a new instance of axios exclusively for jsonapi calls.
 * We can add/modify all requests sent through this instance.
 */

const instance = axios.create();

instance.interceptors.request.use( request => {
  if (typeof request.url === 'string' ) {
    let url = new URL(request.url);

    if (url.searchParams.get('jsonapi_include') === null) {
      url.searchParams.set('jsonapi_include', '0');
    }

    if (url.searchParams.get('filter[status][value]') === null) {
      url.searchParams.set('filter[status][value]', '1');
    }

    request.url = url.href;
  }
  return request;
});

export default instance;
