import React from 'react';

import cx from "classnames";

import "./grid.scss";

interface IProps {
  columns: number,
  div?: boolean,
  ol?: boolean,
  ul?: boolean,
}

class Grid extends React.Component<IProps> {
  render() {
    let parentElement = Object.keys(this.props).filter((key) => {
      if (typeof this.props[key] == 'boolean') {
        return this.props[key]
      };

      return false;
    })[0];

    let childElement = 'div';

    if (parentElement !== 'div') {
      childElement = 'li';
    }

    return (
      React.createElement(
        parentElement,
        {
          'className': cx('grid', `grid--${this.props.columns}-columns`)
        },
        React.Children.map(this.props.children, (child: any) => {
          return (
            React.createElement(
              childElement,
              {
                'className': 'grid__item'
              },
              child
            )
          )
        })
      )
    )
  }
}

export default Grid;
